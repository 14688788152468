@font-face {
  font-family: "Nunito";
  src: url("assets/fonts/Nunito-Regular.woff2") format("woff2");
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Nunito-Bold";
  src: url("assets/fonts/Nunito-Bold.woff2") format("woff2");
  font-style: normal;
  font-display: swap;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}
